//$dark-grey: #363636;
//$medium-grey: #484848;
//$light-grey: #8c8c8c;
//$brand-color: #FFAA00;
//$brand-secondary-color: #33BBFF;
//
//$menu-item-color: whitesmoke;
//$menu-item-hover-background-color: hsl(0, 0, 10%);
//
//$main-background: $medium-grey;
//$main-text-color: whitesmoke;
//$dark-text-color: $dark-grey;
//
//
//$table-header-color: $light-grey;
//$table-row-odd: $medium-grey;
//$table-row-even: $medium-grey;

// color definitions
$orange: rgba(255, 138, 0, 1);
$blue: rgba(51, 187, 255, 1);
$veld-grey: #363636;
$veld-grey-darker: #242424;

// main scheme
$scheme-main: $veld-grey;
$scheme-invert: $grey-lighter;
$background: $veld-grey;

// main classifications
$primary: $orange;
$info: $blue;

// text colours
$text: $grey-lighter;
$text-strong: $white;
$link: $primary;
$link-hover: $primary;
$link-active: $primary;
$link-active-border: $primary;

// specific components and elements
$border: $grey-dark;
// box
$box-background-color: $grey-darker;
$box-shadow: 0 0.5em 1em -0.125em rgba($black, 0.3), 0 0px 0 1px rgba($black, 0.1);
// panel
$panel-shadow: 0 0.5em 1em -0.125em rgba($black, 0.3), 0 0px 0 1px rgba($black, 0.1);
$panel-tab-active-color: white;
$panel-tab-active-border-bottom-color: $primary;
$panel-item-border: 1px solid $grey-dark;
// menu 
$menu-item-hover-background-color: $veld-grey-darker;
// form 
$input-disabled-background-color: $scheme-main;
$input-disabled-border-color: $scheme-main;

$modal-background-background-color: hsla(0, 0, 0, 0.83);
